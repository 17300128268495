export default [
    {
        id: '@reconciliation',
        path: '/reconciliation',
        ref: '/',
        onload: function () { return import('./ReconciliationView'); },
        store: function () { return import('./supplier-manage.store'); },
        dependencies: ['@reconciliation-setting', '@supplier-file']
    },
    // {
    //   path: '/reconciliation-detail/:id',
    //   ref: '/',
    //   onload: () => import('./detail-page/ReconciliationDetailPage'),
    //   store: () => import('./supplier-manage.store'),
    //   dependencies: ['@supplier-setting', '@supplier-file']
    // },
    {
        point: '@@menus',
        onload: function () { return [
            {
                id: 'reconciliation',
                pId: 'reconciliationManage',
                permissions: ['SYS_ADMIN'],
                weight: 10,
                label: i18n.get('对账结算管理'),
                href: '/reconciliation'
            }
        ]; }
    },
    {
        point: '@@layers',
        prefix: '@reconciliation',
        onload: function () { return import('./layers'); }
    }
];
